import React from 'react';
import styled from 'styled-components';

import Mastodon from './icons/Mastodon.jsx';
import Twitter from './icons/Twitter.jsx';
import Rss from './icons/Rss.jsx';
import Github from './icons/Github.jsx';
import LinkedIn from './icons/LinkedIn.jsx';

const Footer = styled.footer`
  & {
    position: absolute;
    display: flex;
    height: 50px;
    line-height: 50px;
    bottom: 0;
    width: calc(100% - 1.25rem);
    justify-content: center;
    padding-bottom: 50px;
  }

  & > a:not(:first-child) {
    margin-left: 1.5em;
  }

  & > a {
    color: lightgrey;
  }
`;

export default () => (
  <Footer>
    <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
      <Rss />
    </a>
    <a href="https://toot.cafe/@stevegenoud" target="_blank" rel="me">
      <Mastodon />
    </a>
    <a href="https://mobile.twitter.com/stevegenoud" target="_blank">
      <Twitter />
    </a>{' '}
    <a href="https://github.com/sgenoud" target="_blank">
      <Github />
    </a>{' '}
    <a href="https://www.linkedin.com/in/stevegenoud/" target="_blank">
      <LinkedIn />
    </a>
  </Footer>
);
