import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';

import { rhythm, scale } from '../utils/typography';
import Footer from './Footer.jsx';

const GlobalStyle = createGlobalStyle`
  a {
    color: #5eadcb;
  }
  a:hover {
    color: #5f7c8c;
  }

  @font-face {
    font-family: "HKGrotesk";
    font-weight: 500;
    src: url("/fonts/HKGrotesk-Regular.woff2") format("woff2");
   }

  @font-face {
    font-family: "HKGrotesk";
    font-weight: 300;
    src: url("/fonts/HKGrotesk-Light.woff2") format("woff2");
   }

  @font-face {
    font-family: "HKGrotesk";
    font-weight: bold;
    src: url("/fonts/HKGrotesk-Bold.woff2") format("woff2");
   }

  body {
    font-family: HKGrotesk, sans-serif;
    font-weight: 300;
    overflow-x: hidden;
    line-height:normal;
  }

  :not(pre) > code[class*="language-"] {
      font-size: 0.85em;
  }

  a.anchor {
      opacity: 0.3;
  }

  li {
    margin-bottom: ${rhythm(0.25)};
  }

  pre {
    margin-bottom: ${rhythm(1)} !important;
  }

`;

const Wrapper = styled.div`
  & {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    padding-bottom: 50px;
  }

  & iframe {
    width: 100%;
    max-width: 590px;
    height: 400px;
    border-style: none;
  }
`;

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      );
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            color: '#5eadcb',
            marginBottom: rhythm(-1),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <Wrapper
        style={{
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
        <Footer />
        <GlobalStyle />
      </Wrapper>
    );
  }
}

export default Layout;
