import Typography from 'typography';
import SternGrove from 'typography-theme-stern-grove';

SternGrove.overrideThemeStyles = ({ rhythm }) => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    'h2,h3,h4,h5,h6': {
      marginBottom: rhythm(1 / 2),
      marginTop: rhythm(1.5),
    },
    h6: {
      marginTop: rhythm(1),
    },
  };
};

delete SternGrove.googleFonts;

const typography = new Typography(SternGrove);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
